import React, { useEffect, useMemo, useCallback } from 'react';
import { Form, useFormikContext } from 'formik';
import { LoaderSimple } from '@concerto-home/core';
import { Col, Row } from 'react-bootstrap';
import Button from 'features/dashboard/components/button/button';
import { trackClickValideInfo } from 'data-layer';
import SimulationAPI from 'features/primes/simulation/questionnaire/services/simulationApi';
import { useSharedValue } from 'states';
import { useNavigate, useParams } from 'react-router';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import FormField from './inputFields/FormField';
import { useRevenusFiscaux } from '../hooks/useRevenusFiscaux';
import { Action, FormValues } from '../interfaces/generationAAT';
import { formatPhone } from '../helpers/functions';
import FoyerFiscalFields from './inputFields/FoyerFiscalFields';

interface FormGenerationProps {
  dispatch: React.Dispatch<Action>;
  simulation: Resultat | undefined;
  isLoading: boolean;
  hasCategorieMenage: boolean;
}

const FormGenerationAAT: React.FC<FormGenerationProps> = ({ dispatch, simulation, isLoading: isLoadingSimulation, hasCategorieMenage }) => {
  const user = useSharedValue('security:user');
  const navigate = useNavigate();
  const { values, setFieldValue, errors } = useFormikContext<FormValues>();
  const { nombreDeFoyersFiscaux, isResidencePrincipale, nombreDePersonnes, adresseLogement, revenusFiscaux: selectedRevenue, telephone } = values;
  const { simulationId } = useParams();

  if (!simulationId) {
    return null;
  }

  const { revenusFiscaux, isLoading } = useRevenusFiscaux(nombreDePersonnes, adresseLogement || '');
  const { isAdressNotComplete } = simulation || {};

  useEffect(() => {
    if (user) {
      setFieldValue('foyerFiscal1Nom', user?.lastName);
      setFieldValue('foyerFiscal1Prenom', user?.firstName);
    }
  }, [user, setFieldValue]);

  useEffect(() => {
    if (!isLoadingSimulation && simulation) {
      setFieldValue('nombreDePersonnes', simulation.profile?.numberOfPeople || 0);
      const { street, postalCode, city, complement } = simulation.logement?.address || {};
      const address = `${street || ''} ${complement || ''} - ${postalCode || ''} ${city || ''} `;
      setFieldValue('adresseLogement', address);
      setFieldValue('telephone', formatPhone(simulation.profile?.coordonnees?.telephone));
      setFieldValue('ville', city);
      setFieldValue('codePostal', postalCode);
    }
  }, [isLoadingSimulation, simulation]);

  useEffect(() => {
    if (!isLoading) {
      setFieldValue('revenusFiscauxChoices', revenusFiscaux);
    }
  }, [isLoading, revenusFiscaux, setFieldValue]);

  useEffect(() => {
    dispatch({ type: 'SET_FIELD', field: 'nombreDeFoyersFiscaux', value: nombreDeFoyersFiscaux });
  }, [dispatch, nombreDeFoyersFiscaux]);

  const isDisabled = useMemo(() => errors && Object.keys(errors).length > 0, [errors]);

  const handleUpdateSimulation = useCallback(async () => {
    if (isDisabled) return;

    const payload = {
      ...(!hasCategorieMenage && {
        categorie: selectedRevenue,
      }),
      nombrePersonnes: nombreDePersonnes,
      isOccupant: isResidencePrincipale,
      adresse: adresseLogement,
      telephone,
      foyers_fiscaux: Array.from({ length: nombreDeFoyersFiscaux || 1 }).map((_, index) => ({
        nom: values[`foyerFiscal${index + 1}Nom` as keyof FormValues],
        prenom: values[`foyerFiscal${index + 1}Prenom` as keyof FormValues],
      })),
      ...(isAdressNotComplete && {
        voieTravaux: values.adresseTravaux,
        villeTravaux: values.ville,
        codePostalTravaux: values.codePostal,
      }),
    };

    const response = await SimulationAPI.updateSimulationInfos(simulationId, payload);
    if (response && response.success) {
      navigate(`/simulation-prime/les-etapes/${simulationId}`, {
        state: { operation: simulation?.travaux?.operation },
      });
    }

    trackClickValideInfo(simulation as Resultat, nombreDePersonnes.toString(), selectedRevenue || simulation?.profile?.categorie || '');
  }, [isDisabled, isResidencePrincipale, adresseLogement, nombreDeFoyersFiscaux, nombreDePersonnes, selectedRevenue, simulation, simulationId, telephone, values, navigate]);

  if (isLoadingSimulation || isLoading) {
    return <LoaderSimple />;
  }

  return (
    <Form>
      <div className="aat__content--form__panel">
        <Row>
          <Col md={6}>
            <FormField type="number" min={1} name="nombreDeFoyersFiscaux" label="Nombre de foyers fiscaux" />
          </Col>
          <Col xs={6}>
            <FormField type="number" min={1} name="nombreDePersonnes" label="Nombre de personnes vivant dans le logement" />
          </Col>
        </Row>
        {Array.from({ length: nombreDeFoyersFiscaux || 1 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <FoyerFiscalFields key={index} index={index} />
        ))}
        <Row>
          <Col md={12}>
            <FormField type="switch" name="isResidencePrincipale" label="Les travaux ont lieux dans ma résidence principale" />
          </Col>
          {!isResidencePrincipale && (
            <Col md={6} className="m-4">
              <FormField type="adress" name="adresseLogement" label="Adresse du logement" />
            </Col>
          )}
        </Row>
        {!hasCategorieMenage && (
          <Row>
            <Col md={12}>
              <FormField type="choice" name="revenusFiscaux" label="Revenus fiscaux" />
            </Col>
          </Row>
        )}
        <Col md={6}>
          <FormField type="text" isNumeric name="telephone" label="Votre numéro de téléphone :" />
        </Col>
        {isAdressNotComplete && (
          <Row>
            <Col md={12}>
              <h3 className="p-4 pb-2">
                <b>Merci de compléter l&apos;adresse des travaux. Toute erreur ou incomplétude dans votre adresse ou celle des travaux peut engendrer le rejet de votre demande de prime.</b>
              </h3>
            </Col>
            <Col md={6}>
              <FormField type="text" name="adresseTravaux" label="Adresse" />
            </Col>
            <Col md={2}>
              <FormField type="text" isNumeric name="codePostal" label="Code postal" max={5} />
            </Col>
            <Col md={4}>
              <FormField type="text" name="ville" label="Ville" />
            </Col>
          </Row>
        )}
      </div>
      <div className="m-4 flex justify-content-center">
        <Button label="Valider ma demande" id="validate-question" type="submit" onClick={handleUpdateSimulation} isDisabled={isDisabled} />
      </div>
    </Form>
  );
};

export default FormGenerationAAT;
