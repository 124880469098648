import { REVENUE_QUESTION_ID } from 'utils/constants';
import { InitialQuestionApi } from '../interfaces/questionnaireAPI';
import { ModeAffichageDisplay, ModeReponseType, QuestionPrime } from '../interfaces/questionnaire';

export function mapAPIQuestion(data: InitialQuestionApi): QuestionPrime {
  const question = data?.question ?? {};

  const isChoiceAndNoResponse = question.mode_reponse === 'choix_unique' && question.reponses?.length === 0 && question.uuid_question !== REVENUE_QUESTION_ID;

  const isSaisieNumerique = question.mode_reponse === 'saisie_numerique' || question.mode_reponse === 'saisie_decimal';
  const isAdresse = question.mode_reponse === 'saisie_adresse';

  return {
    parcoursId: data?.uuid_parcours,
    questionId: question?.uuid_question,
    label: question?.libelle,
    labelInput: question?.libelle_saisie_numerique ?? '',
    more: '',
    type: ModeReponseType[question.mode_reponse] ?? 'column',
    alias: ModeReponseType[question.mode_reponse] ?? null,
    progression: 0,
    display: ModeAffichageDisplay[question.mode_affichage],
    unit: question?.unite_saisie_numerique ?? null,
    // response: question?.valeur ?? question.valeur_defaut ? Number(question.valeur_defaut) : '',
    response: question?.valeur ?? (question.valeur_defaut && isSaisieNumerique ? Number(question.valeur_defaut) : question.valeur_defaut ?? ''),
    userAnswer: {
      questionId: question?.uuid_question,
      // value: question.valeur_defaut ? Number(question.valeur_defaut) : '',
      value: question.valeur_defaut && isSaisieNumerique ? Number(question.valeur_defaut) : question.valeur_defaut ?? '',
      parcoursId: data?.uuid_parcours ?? '',
      response: question.valeur_defaut && isAdresse ? [question.valeur_defaut] : question?.reponses?.map((reponse) => String(reponse.uuid_reponse)) ?? [],
    },
    rules: {
      required: true,
      min: question?.min ?? 1,
      max: question?.max ?? undefined,
    },
    why: question?.libelle_info ?? '',
    help: question?.libelle_aide ?? '',
    choices: question?.reponses?.map((reponse) => ({
      id: reponse.uuid_reponse,
      label: reponse.libelle,
      picto: reponse.pictogramme,
      value: String(reponse.valeur),
    })),
    group: {
      id: Math.random(),
      label: data?.libelle_groupe,
      progression: question.progression,
    },
    sortie: {
      id: data?.sortie?.id,
      type: data?.sortie?.type,
      picto: data?.sortie?.picto,
      title: data?.sortie?.titre,
      text: data?.sortie?.description,
    },
    warning: {
      id: data?.warning?.id,
      type: data?.warning?.type,
      picto: data?.warning?.picto,
      title: data?.warning?.titre,
      text: data?.warning?.description,
    },

    shouldExit: Boolean(data?.sortie?.titre || data?.sortie?.description || data?.sortie?.picto),
    shouldWarn: Boolean(data?.warning?.titre || data?.warning?.description || data?.warning?.picto),
    isStepProgression: question?.is_etape_progression,
    codeTravaux: data?.code_travaux,
    isCodeTravauxRequired: question?.code_travaux_requis,
    responseUrl: question?.reponses_url,
    isLastQuestion: data?.isLastQuestion,
    isChoiceAndNoResponse,
  };
}
