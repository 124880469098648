import React, { useReducer } from 'react';
import './informationsAAT.scss';
import { Formik } from 'formik';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { createValidationSchema } from 'features/primes/gestion-dossier/aat/components/validationSchema';

import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { useParams } from 'react-router';
import FormGenerationAAT from '../components/FormGenerationAAT';
import { Action, FormValues } from '../interfaces/generationAAT';
import Banner from '../components/banner';

const initialValues: FormValues = {
  nombreDeFoyersFiscaux: 1,
  nombreDePersonnes: 1,
  foyerFiscal1Nom: '',
  foyerFiscal1Prenom: '',
  isResidencePrincipale: true,
  adresseLogement: '',
  revenusFiscaux: '',
  revenusFiscauxChoices: [],
  telephone: '',
  adresseTravaux: '',
  ville: '',
  codePostal: '',
};

const actionHandlers = {
  SET_FIELD: (state: FormValues, action: Action) => ({ ...state, [action.field]: action.value }),
};

const formReducer = (state: FormValues, action: Action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

const InformationsAAT = () => {
  const [state, dispatch] = useReducer(formReducer, initialValues);

  const { simulationId } = useParams();

  if (!simulationId) {
    return null;
  }

  const { simulation, isLoading } = useSimulationResultat(simulationId);
  const { isAdressNotComplete } = simulation || {};
  const { operation } = simulation?.travaux || {};
  const hasCategorieMenage = simulation?.profile?.categorie !== null;

  return (
    <div className="aat">
      <div className="aat__container">
        <Banner operation={operation || ''} />
        <div className="aat__content aat-questions">
          <div className="aat__content--form ">
            <div className="aat__content--form__header">
              <div className="aat__content--form__header__title">Veuillez confirmer les éléments suivants :</div>
              <div className="aat__content--form__header__subtitle">
                <div className="aat__content--form__header__subtitle--icon">
                  <BsFillInfoCircleFill size={30} color="#F4A008" />
                </div>
                <div className="aat__content--form__header__subtitle--text">
                  Ces informations sont nécessaires pour garantir le montant de la prime ainsi que la conformité de votre dossier et obtenir le versement de votre prime. <br /> Vos données personnelles sont utilisées uniquement par Sonergia afin de calculer vos primes.
                </div>
              </div>
            </div>
            <div className="aat__content--form__content">
              <Formik initialValues={state} validationSchema={createValidationSchema(state, isAdressNotComplete, hasCategorieMenage)} onSubmit={(values) => console.log(values)}>
                <FormGenerationAAT dispatch={dispatch} simulation={simulation} isLoading={isLoading} hasCategorieMenage={hasCategorieMenage}  />
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationsAAT;
