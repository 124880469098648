import React, { useEffect, useState } from 'react';

import { Progression, resolveInputGeneric, LoaderSimple, Label, Actions, validateResponseValue } from 'core';
import ReactMarkdown from 'react-markdown';
import './questionnaire.scss';

import Exit from 'features/primes/simulation/questionnaire/components/exit/exit';
import PrimeApi from 'features/primes/simulation/questionnaire/services/primeApi';

import lang from 'features/primes/simulation/questionnaire/lang/questionnaire.json';

import { Answer, ModeReponseType, QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

import { useLocation, useNavigate } from 'react-router-dom';
import useGroups from 'features/primes/simulation/questionnaire/hooks/useGroups';
import useInitialQuestion from 'features/primes/simulation/questionnaire/hooks/useInitialQuestion';
import useResponsesIncomeFiscal from 'features/primes/simulation/questionnaire/hooks/useResponsesIncomeFiscal';
import useProgression from 'features/primes/simulation/questionnaire/hooks/useProgression';

import { getTravauxQuestions } from 'features/primes/simulation/questionnaire/hooks/getTravauxQuestions';
import { handleEnterKeyPress } from 'utils/form';
import { getEnv } from 'utils/env';
import { getUserSource, setUserSource, getUserPrescripteur, setUserPrescripteur } from 'states';
import { CHOIX_FORMULE, QUESTION_CHOIX_FORMULE, VALORISATION_FORMULE } from 'utils/constants';
import { addQuestions, categorizeQuestions, createUserAnswer, findPreviousBonificationQuestionIndex, getFormuleId, getUserAnswersByType, resetSkippedQuestions, skipIneligibleAndAnsweredQuestions } from '../utils/functions';
import { useTracking } from '../hooks/useTracking';
import usePartenaire from '../hooks/usePartenaire';
import { saveToLocalStorage } from '../../resultat/helpers/functions';

export default function Questionnaire(): React.ReactElement {
  const navigate = useNavigate();
  const userSource = getUserSource();
  setUserPrescripteur(getUserPrescripteur());
  const location = useLocation();
  const { state } = location;
  const { surveyId } = state || {};

  const partenaire = usePartenaire();

  // Source
  if (userSource !== 'concerto-primes') {
    setUserSource('concerto-primes');
  }

  // States
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<Answer[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [codeTravaux, setCodeTravaux] = useState<string>('');
  const [responseUrl, setResponseUrl] = useState<string>('');
  const [pendingNextQuestion, setPendingNextQuestion] = useState<boolean>(false);

  // Hooks
  const { groups, setGroups, isLoading } = useGroups();
  const { questions, setQuestions } = useInitialQuestion({
    groups,
    partenaire,
    surveyId: Number(surveyId),
  });
  useResponsesIncomeFiscal({
    responseUrl,
    userAnswers,
    questions,
    currentQuestionIndex,
    setQuestions,
  });

  const currentQuestion = questions[currentQuestionIndex];
  const currentGroup = groups.find((group) => group.label === currentQuestion?.group?.label) || groups[0];
  const isEndOfQuestionnaire = currentQuestion?.parcoursId === VALORISATION_FORMULE;

  const findFirstBonificationQuestionIndex = () => questions.findIndex((question) => question.isBonification);
  const getNextBonificationQuestionIndex = () => questions.findIndex((question) => question.isBonification && !question.skip && questions.indexOf(question) > currentQuestionIndex);

  // is question mpr exists
  const isMprQuestionExists = questions.some((question) => question.isMpr);

  const getNonTravauxUserAnswers = () => getUserAnswersByType(questions, 'question');
  const getTravauxUserAnswers = () => getUserAnswersByType(questions, 'operande');
  const getBonificationUserAnswers = () => getUserAnswersByType(questions, 'bonification');
  const getMprUserAnswers = () => getUserAnswersByType(questions, 'mpr');

  // Tracking
  const { trackQuestionAnswer, trackEndSimulation } = useTracking(questions, currentQuestionIndex);
  // End Tracking

  const shouldExit = currentQuestion?.shouldExit || false;
  const shouldWarn = currentQuestion?.shouldWarn || false;
  const { title: sortieTitle, picto: sortiePicto, text: sortieText } = currentQuestion?.sortie || {};
  const { title: warningTitle, picto: warningPicto, text: warningText } = currentQuestion?.warning || {};

  const [showWarning, setShowWarning] = useState<boolean>(false);
  useEffect(() => {
    setShowWarning(shouldWarn);
  }, [shouldWarn]);

  const { increaseProgression, decreaseProgression } = useProgression(currentQuestion, currentGroup, setGroups);

  const buildInitialPayload = () => {
    const payload = {
      ...currentQuestion.userAnswer,
      ...(currentQuestion.isCodeTravauxRequired && { codeTravaux }),
    };
    return payload;
  };

  const handleCodeTravaux = (response: QuestionPrime) => {
    if (response.codeTravaux) {
      setCodeTravaux(response.codeTravaux);
    }
  };

  const saveUserAnswersToLocalStorage = (formuleId?: string) => {
    if (formuleId) {
      saveToLocalStorage('formuleId', formuleId);
    }
    saveToLocalStorage('userAnswersWithoutTravaux', getNonTravauxUserAnswers());
    saveToLocalStorage('userAnswersTravaux', getTravauxUserAnswers());
    saveToLocalStorage('userAnswersBonification', getBonificationUserAnswers());
    saveToLocalStorage('userAnswersMpr', getMprUserAnswers());
  };

  const handleSimulation = async (formuleId?: string) => {
    saveUserAnswersToLocalStorage(formuleId);

    navigate('/simulation-prime/creation', { state });
    return null;
  };

  const handleLastQuestion = async (response: QuestionPrime) => {
    if (response.isLastQuestion) {
      const userAnswersWithoutTravaux = getNonTravauxUserAnswers();
      const travauxQuestions = await getTravauxQuestions(currentQuestion.parcoursId, userAnswersWithoutTravaux, partenaire);
      addQuestions(questions, setQuestions, currentQuestionIndex, travauxQuestions);
    }
  };

  useEffect(() => {
    if (currentQuestion?.emptyQuestion) {
      handleSimulation(currentQuestion.formuleId);
    }
  }, [currentQuestion]);

  const handleQuestionResponseUrl = (response: QuestionPrime) => {
    if (response.responseUrl && response.responseUrl !== '') {
      setResponseUrl(response.responseUrl);
    }
  };

  const postUserAnswerToGetNextQuestion = async () => {
    const initialPayload = buildInitialPayload();
    const questionResponse = await PrimeApi.postQuestion({
      query: initialPayload,
      partenaire,
      surveyId: Number(surveyId),
    });

    handleCodeTravaux(questionResponse);
    handleLastQuestion(questionResponse);

    addQuestions(questions, setQuestions, currentQuestionIndex, questionResponse);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    handleQuestionResponseUrl(questionResponse);
  };

  const resetWarningsAndErrorsIfNeeded = () => {
    if (currentQuestion?.shouldWarn) {
      setShowWarning(false);
      setErrors([]);
    }
  };

  const handleNonOperandeAndBonification = async () => {
    if (!currentQuestion.isTravaux && !currentQuestion.isBonification && !currentQuestion.isMpr) {
      await postUserAnswerToGetNextQuestion();
    }
  };

  const handleBonificationMprQuestions = () => {
    if (!currentQuestion?.isBonification) {
      increaseProgression();
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      return;
    }

    const nextQuestionIndex = getNextBonificationQuestionIndex();
    if (nextQuestionIndex !== -1) {
      setCurrentQuestionIndex(nextQuestionIndex);
      return;
    }

    setCurrentQuestionIndex(isMprQuestionExists ? currentQuestionIndex + 1 : questions.length - 1);
  };

  const updateUserAnswers = (question: QuestionPrime) => {
    const updatedUserAnswers = [...userAnswers];
    const existingAnswerIndex = updatedUserAnswers.findIndex((answer) => answer.questionId === question.questionId);

    if (existingAnswerIndex !== -1) {
      updatedUserAnswers[existingAnswerIndex] = question.userAnswer;
    } else {
      updatedUserAnswers.push(question.userAnswer);
    }

    setUserAnswers(updatedUserAnswers);
  };

  // Fetch next question
  const handleNextQuestion = async () => {
    if (!questions.length) {
      return;
    }

    resetWarningsAndErrorsIfNeeded();

    const errorsResponse = validateResponseValue(currentQuestion, currentQuestion?.response);
    if (errorsResponse.length > 0) {
      setErrors(errorsResponse);
      return;
    }

    updateUserAnswers(currentQuestion);
    await handleNonOperandeAndBonification();

    if (isEndOfQuestionnaire) {
      await handleSimulation();
      return;
    }

    handleBonificationMprQuestions();
  };

  // Fetch next question on enter key press
  useEffect(() => {
    if (pendingNextQuestion) {
      handleNextQuestion();
      setPendingNextQuestion(false);
    }
  }, [pendingNextQuestion]);

  const handleChange = (newValue: string) => {
    if (!questions.length) return;

    const updatedQuestions = [...questions];
    const isChoiceType = currentQuestion.type === ModeReponseType.choix_unique;

    const selectedChoice = currentQuestion.choices?.find((choice) => choice?.value?.toString() === newValue?.toString());

    currentQuestion.response = newValue;
    currentQuestion.userAnswer = createUserAnswer(currentQuestion, newValue, selectedChoice);

    const { nonBonificationQuestions, bonificationEligibiltyQuestions, bonificationChoicesFormuleQuestions, bonificationFormulesQuestions, choicesFormuleQuestions, valorisationFormulesQuestions, mprQuestions } = categorizeQuestions(updatedQuestions);

    const updatedBonificationQuestions = skipIneligibleAndAnsweredQuestions(bonificationEligibiltyQuestions, currentQuestion, selectedChoice);

    let finalBonificationFormulesQuestions = bonificationFormulesQuestions;
    let finaleValorisationFormulesQuestions = valorisationFormulesQuestions;

    if (currentQuestion.parcoursId.includes(`${CHOIX_FORMULE}`)) {
      const formuleId = getFormuleId(newValue, currentQuestion);
      finaleValorisationFormulesQuestions = finaleValorisationFormulesQuestions.map((question) => ({ ...question, skip: question.formuleId !== formuleId }));
    }

    if (currentQuestion.parcoursId.includes(`${QUESTION_CHOIX_FORMULE}`)) {
      const bonificationFormuleId = getFormuleId(newValue, currentQuestion);
      const choixFormuleLevel = +currentQuestion.parcoursId.split('-')[1].replace('level', '');
      finalBonificationFormulesQuestions = finalBonificationFormulesQuestions.map((question) => {
        const FormuleLevel = +question.parcoursId.split('-')[1].replace('level', '');

        if (FormuleLevel === choixFormuleLevel) {
          return { ...question, skip: question.formuleId !== bonificationFormuleId };
        }
        return question;
      });
    }

    setQuestions([...nonBonificationQuestions, ...choicesFormuleQuestions, ...updatedBonificationQuestions, ...bonificationChoicesFormuleQuestions, ...finalBonificationFormulesQuestions, ...mprQuestions, ...finaleValorisationFormulesQuestions]);

    updateUserAnswers(currentQuestion);

    trackEndSimulation({ selectedQuestion: currentQuestion });
    trackQuestionAnswer({ selectedQuestion: currentQuestion });

    if (isChoiceType) {
      setPendingNextQuestion(true);
    }

    setErrors([]);
  };

  // handle back button
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex === 0) {
      return;
    }

    if (responseUrl !== '') {
      setResponseUrl('');
    }

    const previousQuestion = questions[currentQuestionIndex - 1];

    if (previousQuestion?.isBonification) {
      const previousQuestionIndex = findPreviousBonificationQuestionIndex(currentQuestionIndex, questions);

      if (previousQuestionIndex !== -1) {
        setCurrentQuestionIndex(previousQuestionIndex);
      } else {
        const firstBonificationQuestionIndex = findFirstBonificationQuestionIndex();
        if (firstBonificationQuestionIndex !== -1) {
          setCurrentQuestionIndex(firstBonificationQuestionIndex);
          const updatedBonifications = resetSkippedQuestions(questions);
          setQuestions(updatedBonifications);
        }
      }
      return;
    }

    const updatedQuestions = [...questions];
    if (!currentQuestion?.isTravaux) {
      updatedQuestions.pop();
    }

    setQuestions(updatedQuestions);
    decreaseProgression();
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  if (isLoading) {
    return <LoaderSimple />;
  }

  // check if the next button should be disabled
  const isDisabledNext = errors.length > 0 || currentQuestion?.userAnswer?.value === '' || currentQuestion?.isChoiceAndNoResponse;
  const Input = !shouldExit && currentQuestion ? resolveInputGeneric(currentQuestion) : null;

  return (
    <>
      <div className={`diagnostic ${currentQuestionIndex === 0 ? 'start' : ''} primes`}>
        <Progression isInProgress={currentQuestionIndex > 0} groups={groups} currentGroup={currentGroup} isPrimesApp />
        <div className="diagnosticQuestion">
          {shouldExit && <Exit title={sortieTitle} picto={sortiePicto} text={sortieText} />}

          {showWarning ? (
            <Exit title={warningTitle} picto={warningPicto} text={warningText} />
          ) : (
            Input && (
              <>
                <Label question={currentQuestion} />
                {currentQuestion.isChoiceAndNoResponse ? <h2>Malheureusement, aucun résultat ne correspond à vos critères.</h2> : <Input question={currentQuestion} onChange={(newValue) => handleChange(newValue)} onKeyPress={handleEnterKeyPress(handleNextQuestion)} />}
              </>
            )
          )}
          <Actions
            info={
              <>
                {currentQuestionIndex === 0 && (
                  <span className="diagnosticFooterCgu">
                    <ReactMarkdown linkTarget="_blank" transformLinkUri={(uri) => `${getEnv('CMS_HOST')}${uri}`}>
                      {lang.cgu}
                    </ReactMarkdown>
                  </span>
                )}
              </>
            }
            previousLabel={lang.actions.previous}
            nextLabel={isEndOfQuestionnaire ? lang.actions.calculate : lang.actions.next}
            errors={errors}
            showNext={!shouldExit}
            back={handlePreviousQuestion}
            next={handleNextQuestion}
            disabledPrevious={currentQuestionIndex === 0}
            disabledNext={currentQuestion?.shouldWarn ? false : isDisabledNext}
          />
        </div>
      </div>
    </>
  );
}
