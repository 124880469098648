var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useState } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import questionResource from '../../resources/question';
import { validateResponseValue } from '../../resources/survey/validation';
import { useSharedValue } from '../../states';
import ModalSaveConfirm from './modal/modal-save-confirm';
import config from '../../config';
import { LuChevronLeftCircle, LuChevronRightCircle } from 'react-icons/lu';
var ACTION_SAVE_AND_CONTINUE_LATER = 'save_and_continue_later';
var errorBoxTimeout = null;
function Actions(_a) {
    var next = _a.next, back = _a.back, continueLater = _a.continueLater, disabled = _a.disabled, edition = _a.edition;
    var current = useSharedValue('survey:current');
    var customValidation = useSharedValue('survey:current:validation');
    var questions = useSharedValue('survey:questions');
    var question = questions[current];
    var submitting = useSharedValue('survey:submitting');
    var responses = useSharedValue('survey:responses');
    var response = responses[question.alias];
    var errors = validateResponseValue(question, response, customValidation);
    var hasErrors = errors.length > 0;
    var nextQuestion = function () { return typeof next === 'function' && next(); };
    var handleExit = function () { return typeof continueLater === 'function' && continueLater(); };
    var _b = useState(''), messageModal = _b[0], setMessageModal = _b[1];
    var _c = useState(''), buttonModal = _c[0], setButtonModal = _c[1];
    var _d = useState(false), editionConfirmed = _d[0], setEditionConfirmed = _d[1];
    var _e = useState(''), action = _e[0], setAction = _e[1];
    var initialResponses = useState(responses)[0];
    var initialBatimentGroupeId = useState(responses[questionResource.ALIAS_QUESTION_ADDRESS])[0];
    var _f = useState(false), showErrorBox = _f[0], setShowErrorBox = _f[1];
    var _g = useState(false), showModal = _g[0], setShowModal = _g[1];
    var handleCloseModal = function () { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    var label = function () {
        if (current === 0) {
            return 'Commencer mon diagnostic';
        }
        return question.alias === questionResource.ALIAS_QUESTION_NB_OF_WEEKS ? 'Terminer' : 'Suivant';
    };
    var responsesHasChanged = useCallback(function () { return JSON.stringify(responses) !== JSON.stringify(initialResponses); }, [responses]);
    var bnbHasChanged = useCallback(function () { return initialBatimentGroupeId && initialBatimentGroupeId !== responses[questionResource.ALIAS_QUESTION_ADDRESS]; }, [responses]);
    var makeAction = function (a) {
        if ((a || action) === ACTION_SAVE_AND_CONTINUE_LATER) {
            handleExit();
        }
        else {
            nextQuestion();
        }
    };
    var checkAction = function (a) {
        setAction(a);
        if (!edition || editionConfirmed) {
            makeAction(a);
            return;
        }
        switch (a) {
            case questionResource.ALIAS_QUESTION_ADDRESS:
                if (!bnbHasChanged()) {
                    makeAction(a);
                    break;
                }
                setMessageModal('Attention ! Si vous sélectionnez une adresse différente ou un autre bâtiment sur la carte, vous devrez alors renseigner à nouveau la suite du questionnaire.');
                setButtonModal('Confirmer ma nouvelle adresse');
                handleShowModal();
                break;
            case questionResource.ALIAS_QUESTION_NB_OF_WEEKS:
                if (!responsesHasChanged()) {
                    makeAction(a);
                    break;
                }
                setMessageModal('Attention ! Vous êtes sur le point de finaliser une nouvelle version du questionnaire et votre simulation précédente sera supprimée. Souhaitez-vous continuer ?');
                setButtonModal('Obtenir ma nouvelle analyse énergétique');
                handleShowModal();
                break;
            case ACTION_SAVE_AND_CONTINUE_LATER:
                if (!responsesHasChanged()) {
                    makeAction(a);
                    break;
                }
                setMessageModal('Attention ! Vous êtes sur le point de sauvegarder une nouvelle version du questionnaire et votre simulation précédente sera supprimée. Souhaitez-vous continuer ?');
                setButtonModal('Sauvegarder mon nouveau questionnaire');
                handleShowModal();
                break;
            default:
                makeAction(a);
                break;
        }
    };
    var handleEditionConfirm = useCallback(function () {
        setEditionConfirmed(true);
        makeAction(action);
    }, [action]);
    return (React.createElement("div", { className: "diagnosticFooter ".concat(current === 0 ? 'startPage' : '') },
        current === 0 ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "diagnosticFooterCgu" },
                "En cliquant sur \u00AB Commencer mon diagnostic \u00BB vous acceptez nos\u00A0",
                React.createElement("a", { href: "".concat(String(config.cms_url), "/conditions-generales-dutilisation"), target: "_blank", rel: "noreferrer" }, "Conditions G\u00E9n\u00E9rales d\u2019Utilisation")))) : (React.createElement("button", { type: "button", className: "diagnosticFooterSave ".concat(disabled ? 'invisible' : ''), onClick: function () { return checkAction(ACTION_SAVE_AND_CONTINUE_LATER); }, disabled: disabled || submitting },
            React.createElement(RiDownloadCloud2Line, { size: 25, className: "m-4", color: "#F4A008" }),
            React.createElement("span", { className: "diagnosticFooterSaveLabel" },
                "Sauvegarder ",
                React.createElement("span", null, "et continuer plus tard")))),
        React.createElement("div", { className: "diagnosticFooterActions" },
            current > 0 && (React.createElement("button", { type: "button", className: "btn btn-secondary btn-icon-left", onClick: back, disabled: disabled || submitting },
                React.createElement("span", null,
                    React.createElement(LuChevronLeftCircle, { size: 22, className: "m-2" }),
                    "Pr\u00E9c\u00E9dent"))),
            React.createElement(OverlayTrigger, { trigger: "click", show: showErrorBox, delay: { show: 250, hide: 250 }, overlay: function (props) { return (React.createElement(Popover, __assign({ className: "popoverInfo" }, props),
                    React.createElement(Popover.Body, { className: "popoverInfoBody" },
                        React.createElement("ul", { className: "popoverInfoBodyDetails" }, errors.map(function (error) { return (React.createElement("li", { key: error }, error)); }))))); }, onToggle: function (nextShow) {
                    if (nextShow) {
                        errorBoxTimeout = setTimeout(function () { return setShowErrorBox(false); }, 2000);
                    }
                    else if (errorBoxTimeout) {
                        clearTimeout(errorBoxTimeout);
                        errorBoxTimeout = null;
                    }
                } },
                React.createElement("span", { className: "d-inline-block", onClick: function () { return setShowErrorBox(!showErrorBox && hasErrors); } },
                    React.createElement("button", { type: "button", className: "btn btn-primary ".concat(current === 0 ? 'btn-icon-left' : 'btn-icon-right', " desktop"), onClick: function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            checkAction(question.alias);
                        }, disabled: disabled || hasErrors || submitting },
                        React.createElement("span", null,
                            current === 0 && React.createElement("i", { className: "icon-diagnostic" }),
                            label(),
                            current !== 0 && React.createElement(LuChevronRightCircle, { size: 22, className: "m-2" }))))),
            React.createElement(ModalSaveConfirm, { show: showModal, onHide: handleCloseModal, action: handleEditionConfirm, content: { message: messageModal, button: buttonModal } }))));
}
export default Actions;
