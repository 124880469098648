import React from 'react';
import mockup from '../../assets/images/mockup.png';
import why from '../../assets/images/why.png';
import time from '../../assets/images/time.png';
import calcul from '../../assets/images/calcul.png';
import security from '../../assets/images/security.png';
var StartPage = function () {
    return (React.createElement("div", { className: "diagnosticQuestion" },
        React.createElement("div", { className: "diagnosticQuestionStartIntro" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-lg-6 order-2 order-lg-1" },
                    React.createElement("div", { className: "diagnosticQuestionStartIntroText" },
                        React.createElement("h1", { className: "diagnosticQuestionStartIntroTextTitle" }, "Votre projet de r\u00E9novation \u00E9nerg\u00E9tique commence ici\u00A0!"),
                        React.createElement("h2", { className: "diagnosticQuestionStartIntroTextTitleSub" },
                            React.createElement("p", null, "Vous allez r\u00E9pondre \u00E0 un questionnaire pour obtenir des conseils de travaux de r\u00E9novation bas\u00E9s sur une analyse \u00E9nerg\u00E9tique gratuite de votre logement."),
                            React.createElement("p", null, "Quelques petites choses \u00E0 savoir avant de vous lancer\u2026")))),
                React.createElement("div", { className: "col-lg-6 order-1 order-lg-2" },
                    React.createElement("div", { className: "diagnosticQuestionStartIntroIllu" },
                        React.createElement("img", { style: { width: '40%' }, src: mockup, alt: "Votre projet de r\u00E9novation \u00E9nerg\u00E9tique commence ici\u00A0!" }))))),
        React.createElement("div", { className: "diagnosticQuestionStart" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-lg-6" },
                    React.createElement("div", { className: "diagnosticQuestionStartItem" },
                        React.createElement("div", { className: "diagnosticQuestionStartItemIcon" },
                            React.createElement("img", { src: why, className: "img-fluid", alt: "Pourquoi ce questionnaire\u00A0?" })),
                        React.createElement("div", { className: "diagnosticQuestionStartItemContent" },
                            React.createElement("h3", { className: "diagnosticQuestionStartItemContentTitle" }, "Pourquoi ce questionnaire\u00A0?"),
                            React.createElement("div", { className: "diagnosticQuestionStartItemContentDetails" },
                                React.createElement("p", null,
                                    "L\u2019analyse \u00E9nerg\u00E9tique de votre logement vous apportera ",
                                    React.createElement("b", null, "des solutions de travaux personnalis\u00E9s, concr\u00E8tes et parfaitement adapt\u00E9es"),
                                    " (artisans, aides possibles) pour r\u00E9aliser des \u00E9conomies d\u2019\u00E9nergie"))))),
                React.createElement("div", { className: "col-lg-6" },
                    React.createElement("div", { className: "diagnosticQuestionStartItem" },
                        React.createElement("div", { className: "diagnosticQuestionStartItemIcon" },
                            React.createElement("img", { src: time, className: "img-fluid", alt: "Combien de temps\u00A0?" })),
                        React.createElement("div", { className: "diagnosticQuestionStartItemContent" },
                            React.createElement("h3", { className: "diagnosticQuestionStartItemContentTitle" }, "Combien de temps\u00A0?"),
                            React.createElement("div", { className: "diagnosticQuestionStartItemContentDetails" },
                                React.createElement("p", null,
                                    React.createElement("b", null, "10 \u00E0 15 minutes:"),
                                    " on vous en demande beaucoup mais vous aurez une analyse de qualit\u00E9 :-)."),
                                React.createElement("p", null,
                                    "Pour aller plus vite, ",
                                    React.createElement("b", null, "le questionnaire pourra \u00EAtre pr\u00E9-rempli"),
                                    " gr\u00E2ce \u00E0 ",
                                    React.createElement("u", null, "Go R\u00E9nove"),
                                    " (base de donn\u00E9es publiques des logements) : vous n\u2019aurez plus qu\u2019\u00E0 confirmer vos informations"))))),
                React.createElement("div", { className: "col-lg-6" },
                    React.createElement("div", { className: "diagnosticQuestionStartItem" },
                        React.createElement("div", { className: "diagnosticQuestionStartItemIcon" },
                            React.createElement("img", { src: calcul, className: "img-fluid", alt: "Comment calculez-vous tout \u00E7a\u00A0?" })),
                        React.createElement("div", { className: "diagnosticQuestionStartItemContent" },
                            React.createElement("h3", { className: "diagnosticQuestionStartItemContentTitle" }, "Comment calculez-vous tout \u00E7a\u00A0?"),
                            React.createElement("div", { className: "diagnosticQuestionStartItemContentDetails" },
                                React.createElement("p", null, "Nous ne sommes pas magiciens \u2728 (m\u00EAme si parfois on aimerait bien !), cette analyse \u00E9nerg\u00E9tique vous est propos\u00E9e gr\u00E2ce \u00E0 un moteur de calcul con\u00E7u par le CSTB (Centre Scientifique et Technique du B\u00E2timent), \u00E9tablissement public sp\u00E9cialiste de la performance \u00E9nerg\u00E9tique du b\u00E2timent depuis plus de 70 ans."))))),
                React.createElement("div", { className: "col-lg-6" },
                    React.createElement("div", { className: "diagnosticQuestionStartItem" },
                        React.createElement("div", { className: "diagnosticQuestionStartItemIcon" },
                            React.createElement("img", { src: security, className: "img-fluid", alt: "Comment mes donn\u00E9es sont-elles trait\u00E9es\u00A0?" })),
                        React.createElement("div", { className: "diagnosticQuestionStartItemContent" },
                            React.createElement("h3", { className: "diagnosticQuestionStartItemContentTitle" }, "Comment mes donn\u00E9es sont-elles trait\u00E9es\u00A0?"),
                            React.createElement("div", { className: "diagnosticQuestionStartItemContentDetails" },
                                React.createElement("p", null,
                                    "Les donn\u00E9es personnelles recueillies font l\u2019objet d\u2019un traitement par Sonergia afin d\u2019effectuer une analyse de la consommation d\u2019\u00E9nergie de votre logement et vous proposer des travaux d\u2019\u00E9conomie d\u2019\u00E9nergie",
                                    ' ',
                                    React.createElement("a", { href: "https://sonergia.fr/politique-de-confidentialite/", target: "_blank", rel: "noreferrer" }, "lire notre politique de confidentialit\u00E9"),
                                    ".")))))),
            React.createElement("div", { className: "diagnosticQuestionStartInducement" }, "Vous \u00EAtes pr\u00EAts\u00A0?"))));
};
export default StartPage;
