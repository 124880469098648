import React, { useState } from 'react';
import { Row, Col, Popover, OverlayTrigger, Modal, Table, Stack } from 'react-bootstrap';
import security from '@concerto-home/security';
import { EtiquetteCombinatoire, EtiquetteCombinatoireDetails } from '@concerto-home/etiquette-energetique';
import { works, profits } from './index.data';
import { RiCalculatorLine } from 'react-icons/ri';
var popoverBonus = (React.createElement(Popover, { className: "popoverInfo" },
    React.createElement(Popover.Body, { className: "popoverInfoBody" },
        React.createElement("i", { className: "icon-info popoverInfoBodyIcon" }),
        React.createElement("p", { className: "popoverInfoBodyDetails" }, "Estimation du montant de vos primes : MaPrimeR\u00E9nov\u2019 et prime CEE. Inscrivez-vous pour obtenir le montant exact de vos primes et \u00EAtre accompagn\u00E9 pour les formalit\u00E9s administratives n\u00E9cessaires \u00E0 leur obtention."))));
var formatNumber = function (number) { return String(number).replace(/(.)(?=(\d{3})+$)/g, '$1 '); };
var RecoCard = function (_a) {
    var _b;
    var _c = _a.source, source = _c === void 0 ? 'concerto' : _c, name = _a.name, title = _a.title, subTitle = _a.subTitle, recommended = _a.recommended, data = _a.data, initial = _a.initial, onClickCta = _a.onClickCta, isNote = _a.isNote, showCalculate = _a.showCalculate;
    var _d = useState(false), showDesired = _d[0], setShowDesired = _d[1];
    var _e = useState(false), showModalWorks = _e[0], setShowModalWorks = _e[1];
    var handleCloseModalWorks = function () { return setShowModalWorks(false); };
    var handleShowModalWorks = function () {
        onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('see-earnings', String(title));
        setShowModalWorks(true);
    };
    // retourne la liste des bénéfices dédoublonnés
    var getProfitsFromWorks = function (workList) {
        var listProfits = [];
        workList.forEach(function (workListItem) {
            works.forEach(function (work) {
                if (work.key === workListItem) {
                    work.profits.forEach(function (workProfit) {
                        listProfits.push(workProfit);
                    });
                }
            });
        });
        var listProfitsUniqItem = listProfits.reduce(function (a, b) {
            if (a.indexOf(b) < 0)
                a.push(b);
            return a;
        }, []);
        return listProfitsUniqItem;
    };
    // return true pour afficher l'icon validate dans le tableau des bénéfices
    var getStatusProfit = function (workProfitList, currentProfit) { return workProfitList.includes(currentProfit); };
    // return le label du bénéfice pour le responsive du tableau
    var getLabelProfitFromCode = function (codeProfit) {
        var index = profits.map(function (el) { return el.code; }).indexOf(codeProfit);
        return profits[index].label;
    };
    var worksDesired = data.renovation_works || [];
    var profitsDesired = getProfitsFromWorks(worksDesired);
    return (React.createElement("div", { className: "diagnosticResultBloc" },
        React.createElement("div", { id: name, className: "diagnosticResultBlocAnchor" }),
        React.createElement("div", { className: "diagnosticResultBlocHeader" },
            React.createElement(Row, { className: "diagnosticResultBlocHeaderTop" },
                React.createElement(Col, { sm: 9 },
                    recommended ? (React.createElement(React.Fragment, null,
                        React.createElement("h3", { className: "diagnosticResultTitle" }, "Analyse de vos travaux souhait\u00E9s"),
                        React.createElement("p", { className: "diagnosticResultIntro" },
                            "A la deuxi\u00E8me question du questionnaire, vous avez indiqu\u00E9 avoir une id\u00E9e des travaux que vous souhaitiez r\u00E9aliser.",
                            React.createElement("br", null),
                            "Voici une estimation d\u00E9taill\u00E9e de ces travaux."))) : (React.createElement("h3", { className: "diagnosticResultBlocHeaderTopLabel" },
                        React.createElement("div", { className: "diagnosticResultBlocHeaderTopLabelFirst" }, title),
                        React.createElement("div", { className: "diagnosticResultBlocHeaderTopLabelSecond" }, subTitle))),
                    React.createElement("ul", { className: "diagnosticResultBlocHeaderBadge" }, worksDesired.map(function (key) {
                        return works.map(function (work) {
                            if (work.key === key) {
                                return (React.createElement("li", { key: work.key, className: "diagnosticResultBlocHeaderBadgeItem" },
                                    React.createElement("img", { src: work.icon, alt: work.label }),
                                    work.label));
                            }
                            return '';
                        });
                    }))),
                React.createElement(Col, { sm: 3 },
                    React.createElement(Stack, { className: "text-center" },
                        React.createElement(EtiquetteCombinatoire, { statusAfter: data.dpe_status }),
                        (function () {
                            if (data.financial_gain_per_year > 0) {
                                return (React.createElement("div", null,
                                    React.createElement("div", { className: "diagnosticResultBlocHeaderTopPrice" },
                                        React.createElement("span", null, "Jusqu'\u00E0"),
                                        React.createElement("span", { className: "diagnosticResultBlocHeaderTopPriceLabel" },
                                            formatNumber(data.financial_gain_per_year),
                                            " \u20AC")),
                                    React.createElement("strong", null, "D'\u00E9conomie par an")));
                            }
                            return React.createElement("div", { className: "diagnosticResultBlocHeaderTopPrice" }, "Ces travaux n'impacteront pas votre facture. D\u00E9couvrez vos avantages en confort en cliquant sur \"Voir plus\".");
                        })()))),
            React.createElement(Row, null,
                source != 'concerto-renov' && (React.createElement(Col, { md: 4 },
                    React.createElement("h4", { className: "diagnosticResultBlocLabel" }, "Estimation du prix des travaux"),
                    React.createElement("p", { className: "diagnosticResultBlocPrice" },
                        formatNumber(data.renovation_cost_min_without_incentive),
                        " \u00E0 ",
                        formatNumber(data.renovation_cost_max_without_incentive),
                        " \u20AC"))),
                source != 'concerto-renov' && (React.createElement(Col, { md: 4 },
                    React.createElement("h4", { className: "diagnosticResultBlocLabel" },
                        "Estimation des primes",
                        React.createElement(OverlayTrigger, { trigger: "click", placement: "bottom-start", overlay: popoverBonus, rootClose: true, rootCloseEvent: "click" },
                            React.createElement("i", { className: "icon-info popoverInfoButton" }))),
                    React.createElement("p", { className: "diagnosticResultBlocPrice" },
                        formatNumber(data.incentive_cost_min),
                        " \u00E0 ",
                        formatNumber(data.incentive_cost_max),
                        " \u20AC"))),
                React.createElement(Col, { md: 4 },
                    React.createElement("h4", { className: "diagnosticResultBlocLabel" }, "Confort d'hiver apr\u00E8s travaux"),
                    React.createElement("div", { className: "diagnosticResultConfort", "data-level": data.winter_comfort_indicator },
                        data.winter_comfort_indicator > 2 && React.createElement("span", { className: "diagnosticResultConfortLabel first" }, "1"),
                        data.winter_comfort_indicator < 10 && React.createElement("span", { className: "diagnosticResultConfortLabel last" }, "10")))),
            showCalculate && (React.createElement("div", { className: "diagnosticResultBlocBodyCta" },
                React.createElement("h3", { className: "diagnosticResultBlocBodyCtaTitle" }, "Affinez d\u00E8s maintenant votre montant de prime garanti pour le travaux souhait\u00E9"),
                React.createElement("button", { type: "button", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('calculate'); }, className: "btn btn-primary btn-icon-left" },
                    React.createElement("span", null,
                        React.createElement(RiCalculatorLine, { size: 20, className: "m-2" }),
                        " Calculer ma prime"))))),
        React.createElement("button", { type: "button", className: "diagnosticResultBlocToggle", onClick: function () {
                setShowDesired(!showDesired);
                if (!showDesired) {
                    onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('see-more', String(title));
                }
            } }, showDesired ? (React.createElement(React.Fragment, null,
            React.createElement("span", null, "Voir moins"),
            React.createElement("i", { className: "icon-chevron-up" }))) : (React.createElement(React.Fragment, null,
            React.createElement("span", null, "Voir plus"),
            React.createElement("i", { className: "icon-chevron-down" })))),
        showDesired && (React.createElement("div", { className: "diagnosticResultBlocBody" },
            React.createElement("h4", { className: "diagnosticResultBlocBodyTitle" }, "Travaux pr\u00E9conis\u00E9s"),
            React.createElement(Row, { className: "diagnosticResultBlocBodyWorksContainer" }, (_b = data.renovation_works) === null || _b === void 0 ? void 0 :
                _b.map(function (key) {
                    return works.map(function (work) {
                        if (work.key === key) {
                            return (React.createElement(Col, { md: 4, key: work.key },
                                React.createElement("div", { className: "diagnosticResultBlocBodyWorks" },
                                    React.createElement("div", { className: "diagnosticResultBlocHeaderBadgeItem" },
                                        React.createElement("img", { src: work.icon, alt: work.label }),
                                        work.label),
                                    React.createElement("p", { className: "diagnosticResultBlocBodyWorksDetails" }, work.desc))));
                        }
                        return '';
                    });
                }),
                React.createElement(Col, { md: 12 },
                    React.createElement("p", { className: "diagnosticResultBlocBodyWorksDesclaimer" }, "Les pr\u00E9conisations de travaux se basent notamment sur la date de construction de votre logement et la r\u00E9glementation thermique. Celles-ci peuvent proposer des travaux (e.g isolation) d\u00E9j\u00E0 r\u00E9alis\u00E9s mais dont la dur\u00E9e de vie n\u00E9cessite de les renouveler."))),
            React.createElement(Row, null,
                React.createElement(Col, { lg: 7 },
                    React.createElement("h4", { className: "diagnosticResultBlocBodyTitle mb-5" }, "Gains en \u00E9nergie apr\u00E8s travaux"),
                    React.createElement(EtiquetteCombinatoireDetails, { statusAfter: data.dpe_status, statusAfterValue: data.dpe_status_value, statusBefore: initial.dpe_status, statusBeforeValue: initial.dpe_status_value, co2EmissionsValue: data.dpe_co_2_emissions_value })),
                React.createElement(Col, { lg: 5 },
                    React.createElement("div", { className: "diagnosticResultBlocBodyTitleContainer" },
                        React.createElement("h4", { className: "diagnosticResultBlocBodyTitle" }, "Autres gains"),
                        React.createElement("button", { type: "button", onClick: handleShowModalWorks, className: "diagnosticResultBlocBodyTitleLink" }, "Voir tous les gains")),
                    React.createElement("p", { className: "diagnosticResultBlocLabel" }, "Confort d'hiver"),
                    React.createElement("div", { className: "diagnosticResultConfort multiple" },
                        initial.winter_comfort_indicator > 2 && React.createElement("span", { className: "diagnosticResultConfortLabel first" }, "1"),
                        data.winter_comfort_indicator < 10 && React.createElement("span", { className: "diagnosticResultConfortLabel last" }, "10"),
                        React.createElement("div", { className: "diagnosticResultConfortTime", "data-level": initial.winter_comfort_indicator },
                            React.createElement("span", null, "Avant travaux")),
                        React.createElement("div", { className: "diagnosticResultConfortTime", "data-level": data.winter_comfort_indicator },
                            React.createElement("span", { className: "".concat(data.winter_comfort_indicator - initial.winter_comfort_indicator < 3 ? 'top' : '') }, "Apr\u00E8s travaux"))),
                    React.createElement("p", { className: "diagnosticResultBlocLabel" }, "Confort d'\u00E9t\u00E9"),
                    React.createElement("div", { className: "diagnosticResultConfort multiple" },
                        initial.summer_comfort_indicator > 2 && React.createElement("span", { className: "diagnosticResultConfortLabel first" }, "1"),
                        data.summer_comfort_indicator < 10 && React.createElement("span", { className: "diagnosticResultConfortLabel last" }, "10"),
                        React.createElement("div", { className: "diagnosticResultConfortTime", "data-level": initial.summer_comfort_indicator },
                            React.createElement("span", null, "Avant travaux")),
                        React.createElement("div", { className: "diagnosticResultConfortTime", "data-level": data.summer_comfort_indicator },
                            React.createElement("span", { className: "".concat(data.summer_comfort_indicator - initial.summer_comfort_indicator < 3 ? 'top' : '') }, "Apr\u00E8s travaux"))))),
            (!security.isAuthenticated() || security.getRole() === 'ROLE_CUSTOMER') && (React.createElement("div", { className: "diagnosticResultBlocBodyCta" },
                React.createElement("h3", { className: "diagnosticResultBlocBodyCtaTitle" }, "Vous cherchez un artisan ? Nous pouvons vous aider !"),
                !security.user ? (React.createElement("button", { type: "button", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('contact', String(title), 'Inscrivez-vous'); }, className: "btn btn-primary btn-icon-left" },
                    React.createElement("span", null,
                        React.createElement("i", { className: "icon-validate" }),
                        " Inscrivez-vous"))) : (React.createElement("button", { type: "button", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('contact-pop', String(title), 'Contactez-nous'); }, className: "btn btn-primary btn-icon-left" },
                    React.createElement("span", null,
                        React.createElement("i", { className: "icon-validate" }),
                        " Contactez-nous"))))),
            isNote && (React.createElement("div", { className: "diagnosticResultBlocBodyFooter" },
                React.createElement("i", { className: "icon-deal" }),
                React.createElement("div", { className: "diagnosticResultBlocBodyFooterContent" },
                    "Pour cette pr\u00E9conisation de travaux, vous pouvez b\u00E9n\u00E9ficier d'un",
                    React.createElement("a", { href: "/", className: "diagnosticResultBlocBodyFooterLink", onClick: function (e) {
                            e.preventDefault();
                            onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('contact-gorenove-partner', String(title));
                        } }, "Accompagnateur R\u00E9nov'")))))),
        React.createElement(Modal, { show: showModalWorks, onHide: handleCloseModalWorks, className: "diagnosticResultModal" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Travaux pr\u00E9conis\u00E9s")),
            React.createElement(Modal.Body, null,
                React.createElement(Table, { className: "diagnosticResultTableWorks", striped: true, responsive: true },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, " "),
                            profitsDesired.map(function (profitDesired) {
                                return profits.map(function (profit) {
                                    if (profit.code === profitDesired) {
                                        return (React.createElement("th", { key: profit.code },
                                            React.createElement("div", { className: "diagnosticResultTableWorksThead" },
                                                React.createElement("i", { className: profit.icon }),
                                                profit.label)));
                                    }
                                    return '';
                                });
                            }))),
                    React.createElement("tbody", null, worksDesired.map(function (key) {
                        return works.map(function (work) {
                            if (work.key === key) {
                                return (React.createElement("tr", { key: work.key },
                                    React.createElement("td", { "data-label": "work" }, work.label),
                                    profitsDesired.map(function (profitDesired) { return (React.createElement("td", { key: profitDesired, "data-label": getLabelProfitFromCode(profitDesired) },
                                        React.createElement("div", { className: "diagnosticResultTableWorksCheck" }, getStatusProfit(work.profits, profitDesired) && React.createElement("i", { className: "icon-validate-on" })))); })));
                            }
                            return '';
                        });
                    })))))));
};
export default RecoCard;
