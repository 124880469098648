import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ReplacingSurvey from 'components/account/replacing-survey';
import { questionResource, surveyResource, Survey as SurveyInterface, api, Response, SurveyDisplayer, LoaderSimple, LoaderEnd, isDisplay, transformResponsesForApi, transformResponsesForState, downloadPayload, resolveInput } from 'core';
import security from '@concerto-home/security';

import { useSetSharedState, useSharedState, setUserSource, getUserSource, getSurveySource, getUserPrescripteur, setUserPrescripteur } from 'states';
import { getEnv } from 'utils/env';
import Rejected from 'pages/survey/rejected';

import type { SurveyResponses, Question, Customer } from 'core';
import { handleCollectiveHeaterForm, handleSurveyContinue, handleSurveyPageLoaded, handleSurveyQuestion } from 'data-layer';

let observerTimeout: ReturnType<typeof setTimeout> | null = null;
let token: string | null = null;

export default function Survey(): React.ReactElement {
  const [status, setStatus] = useState('created');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [cstbError, setCstbError] = useState('');
  const [rejected, setRejected] = useState(false);
  // eslint-disable-next-line
  const [resolvingTaskError, setResolvingTaskError] = useState<undefined | null | { errorMessage: string; failingStage: string | number }>(null);
  const [processingTaskUrl, setProcessingTaskUrl] = useState('');
  const [currentStage, setCurrentStage] = useState<number | null | undefined>(null);
  const [rawResponses, setRawResponses] = useState<Response[]>([]);
  const [batimentGroupeId, setBatimentGroupeId] = useSharedState('survey:bnb');
  const [responses, setResponses] = useSharedState('survey:responses');
  const [questions, setQuestions] = useSharedState('survey:questions');
  const [current, setCurrent] = useSharedState('survey:current');
  const [, setSubmitting] = useSharedState('survey:submitting');
  const question = questions[current];
  const [id, setId] = useSharedState('survey:id');
  const setToken = useSetSharedState('survey:token');
  const setErrors = useSetSharedState('survey:errors');
  const setLoginContext = useSetSharedState('security:login');
  const setHasHouseShape = useSetSharedState('survey:has_house_shape');
  const [user] = useSharedState('security:user');
  const navigate = useNavigate();
  const location = useLocation();
  const idParam = useParams().id;
  const buildQuery = () => ({ security_token: token });
  const isDebugCstbEnabled = getEnv('DEBUG_CSTB') === '1';
  const userSource = getUserSource();

  if (userSource !== 'concerto-renov' && userSource !== 'concerto') {
    setUserSource('concerto');
  }

  setUserPrescripteur(getUserPrescripteur());

  // eslint-disable-next-line
  const updateContext = idParam !== 'creer';
  const readSurvey = useCallback((i?: number | string) => surveyResource.read(String(i || id), buildQuery()), [id]);

  const getMotivation = useCallback(() => {
    const motivation: number[] | undefined = responses[questionResource.ALIAS_QUESTION_REASONS_FOR_RENOVATION] ? [...responses[questionResource.ALIAS_QUESTION_REASONS_FOR_RENOVATION]] : [];
    motivation.sort();
    return motivation.join(',');
  }, [current, responses]);

  const observeSurvey = (i?: number | string) => {
    if (observerTimeout) {
      clearTimeout(observerTimeout);
    }

    observerTimeout = setTimeout(() => {
      readSurvey(i).then((survey) => {
        if (survey && getSurveySource(survey, userSource) !== 'concerto-renov') {
          setUserSource('concerto');
        }

        setCurrentStage(surveyResource.STATUS_COMPLETE === survey.status ? 5 : survey.diagnosticStage);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        checkStatus(survey);
        setStatus(survey.status);
        setHasHouseShape(survey.extra?.has_house_shape || false);
        setRawResponses(survey.responses);
        setResponses(transformResponsesForState(questions, survey.responses));
        setResolvingTaskError(survey.resolvingTaskError);

        if (survey.status === surveyResource.STATUS_COMPLETE) {
          navigate(`/analyse-energetique/${survey.id}/diagnostic`, {
            state: {
              query: buildQuery(),
            },
          });
        }
      });
    }, 5000);
  };

  const checkStatus = useCallback(
    (survey: SurveyInterface): boolean => {
      let isEditable = true;

      switch (survey.status) {
        case surveyResource.STATUS_COMPLETE: {
          if (question?.alias === questionResource.ALIAS_QUESTION_NB_OF_WEEKS) {
            navigate(`/analyse-energetique/${survey.id}/diagnostic`, {
              state: {
                query: buildQuery(),
              },
            });
            break;
          }
          isEditable = true;
          break;
        }
        case surveyResource.STATUS_REJECTED: {
          // navigate(`/analyse-energetique/rejected`);
          setRejected(true);
          isEditable = false;
          break;
        }
        case surveyResource.STATUS_PROCESSING: {
          observeSurvey(String(survey.id));
          isEditable = false;
          break;
        }
        case surveyResource.STATUS_RESOLVING: {
          observeSurvey(String(survey.id));
          isEditable = false;
          break;
        }
        case surveyResource.STATUS_IN_PROGRESS:
        default: {
          isEditable = true;
          break;
        }
      }

      return isEditable;
    },
    [question]
  );

  const preparePayload = useCallback(
    (data: SurveyResponses, submitToCstbIfComplete = true) => ({
      id: id || undefined,
      responses: transformResponsesForApi(questions, data),
      submitToCstbIfComplete,
    }),
    [id, questions, rawResponses.length]
  );

  const submit = useCallback(
    (data: SurveyResponses, callback?: (_survey: SurveyInterface) => void, submitToCstbIfComplete = true): void => {
      const action = id ? 'update' : 'create';
      setSubmitting(true);
      setCstbError('');
      surveyResource[action](preparePayload(data, submitToCstbIfComplete), buildQuery())
        .then((survey: SurveyInterface) => {
          setId(String(survey.id));
          setStatus(survey.status);
          setRawResponses(survey.responses);

          if (isDebugCstbEnabled) {
            if (survey.lastCstbError) {
              setCstbError(`Erreur CSTB : ${JSON.stringify(survey.lastCstbError)}`);
            } else if (survey.processingTaskUrl) {
              setProcessingTaskUrl(survey.processingTaskUrl);
            }
          }

          if (survey.token) {
            setToken(survey.token);
            token = survey.token;
          }

          if (typeof callback === 'function') {
            callback(survey);
            return;
          }

          checkStatus(survey);
        })
        .catch(() => {
          setError('Une erreur est survenue');
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    },
    [id, questions, preparePayload, token, question]
  );

  const handleReject = () => {
    // setLoading(true);
    // api.cancel();
    // navigate('/survey/rejected');
    handleCollectiveHeaterForm();
    setRejected(true);
  };

  const showConnexion = useCallback(
    (callback: (_customer: Customer) => void) => {
      setLoginContext({
        show: true,
        callback,
        state: {
          survey: id ? `/api/surveys/${id}` : preparePayload(responses),
          query: buildQuery(),
        },
      });
    },
    [id, responses, preparePayload]
  );

  const handleContinueLater = useCallback(() => {
    handleSurveyQuestion(question, responses, 'save', getMotivation());

    if (!security.user) {
      submit(responses, undefined, false);
      showConnexion((customer: Customer) => {
        if (typeof customer.survey !== 'string' && customer.survey?.id === Number(id)) {
          handleContinueLater();
        }
      });
      return;
    }

    const userSurveyId = user?.survey?.id;

    if (userSurveyId && userSurveyId !== Number(id)) {
      return;
    }

    setLoading(true);
    api.cancel();

    submit(responses, () => {
      navigate('/dashboard');
    });
  }, [responses, id, user]);

  const handleNext = useCallback(
    (c: number, data: SurveyResponses) => {
      window.scrollTo(0, 0);
      if (questions[c - 1]?.alias === questionResource.ALIAS_QUESTION_ADDRESS && data[questionResource.ALIAS_QUESTION_ADDRESS] && data[questionResource.ALIAS_QUESTION_ADDRESS] !== batimentGroupeId) {
        setBatimentGroupeId(data[questionResource.ALIAS_QUESTION_ADDRESS]);
        setStatus(surveyResource.STATUS_RESOLVING);
        submit(data, (survey) => {
          if (checkStatus(survey)) {
            setStatus(survey.status);
          }
        });
      }
    },
    [id, batimentGroupeId, questions]
  );

  const handleComplete = useCallback(() => {
    handleSurveyQuestion(question, responses, 'continue', getMotivation());

    api.cancel();
    submit(responses, (survey) => {
      // For resubmit on edition mode
      if (survey.status === surveyResource.STATUS_IN_PROGRESS && updateContext) {
        submit(responses);
      } else {
        checkStatus(survey);
      }
    });
  }, [responses, id, question]);

  const hydrateFromQuery = (collection: Question[], surveyResponses: SurveyResponses) => {
    const params = new URLSearchParams(location.search);
    const result: SurveyResponses = {};

    Object.keys(surveyResponses).map((alias) => {
      const q = collection.filter((i) => i.alias === alias)[0];
      const name = q?.type === 'choices' ? `${alias}[]` : alias;
      const queryValue = (q?.type === 'choices' ? params.getAll(name) : params.get(name)) || undefined;
      let val = surveyResponses[alias];

      if (queryValue) {
        switch (q.type) {
          case 'choice':
            val = parseInt(queryValue as string, 10);
            break;
          case 'choices':
            val = queryValue.length > 0 ? (queryValue as string[]).map((i) => parseInt(i, 10)) : undefined;
            break;
          case 'float':
            val = parseFloat(queryValue as string);
            break;
          case 'integer':
            val = parseInt(queryValue as string, 10);
            break;
          case 'boolean':
            val = Boolean(queryValue as string);
            break;
          default:
            val = queryValue;
            break;
        }
      }

      params.delete(name);
      result[alias] = val || undefined;

      return null;
    });

    navigate(`${window.location.pathname}?${params.toString()}`);

    return result;
  };

  const getInitialIndex = (collection: Question[], surveyResponses: SurveyResponses) => {
    let i = 0;

    while (collection[i]) {
      const { alias } = collection[i];

      const nextQuestion = collection[i + 1];
      const nextAlias = nextQuestion?.alias || '';
      const nextResponse = surveyResponses[nextAlias];
      const isStartPage = alias === 'start_page';
      const Input = resolveInput(collection[i]) as { isDisplay?: (_r: SurveyResponses) => boolean };

      // Break if current question is the last one
      if (!nextQuestion) {
        break;
      }

      // Start page is displayed only first time
      if (isStartPage && nextResponse === undefined) {
        break;
      }

      // Check for complex inputs
      if (Input && Input.isDisplay?.(surveyResponses)) {
        break;
      }

      // No response found for a required question
      if (isDisplay(collection[i], surveyResponses) && !isStartPage && surveyResponses[alias] === undefined) {
        break;
      }

      i += 1;
    }

    return i;
  };

  const initialize = () => {
    setErrors([]);

    questionResource.all().then((collection: Question[]) => {
      setQuestions(collection);
      if (idParam === 'creer') {
        const surveyResponses = hydrateFromQuery(collection, transformResponsesForState(collection));

        setResponses(surveyResponses);
        setCurrent(0);
        setLoading(false);
        setId(null);
        setBatimentGroupeId(null);
      } else {
        if (!security.user) {
          navigate('/login', {
            state: { from: location.pathname },
          });
          return;
        }

        setId(idParam);
        readSurvey(idParam)
          .then((survey: SurveyInterface) => {
            const surveyResponses = transformResponsesForState(collection, survey.responses);
            const initialIndex = getInitialIndex(collection, surveyResponses);

            setCurrent(
              // eslint-disable-next-line
              updateContext && survey.status === surveyResource.STATUS_COMPLETE ? 1 : initialIndex
            );

            setBatimentGroupeId(surveyResponses[questionResource.ALIAS_QUESTION_ADDRESS]);
            setResolvingTaskError(survey.resolvingTaskError);
            setResponses(surveyResponses);
            setRawResponses(survey.responses);
            setCurrentStage(survey.diagnosticStage);
            setStatus(survey.status);
            setHasHouseShape(survey.extra?.has_house_shape || false);
            checkStatus(survey);
            setLoading(false);

            if (survey.status === surveyResource.STATUS_IN_PROGRESS) {
              handleSurveyContinue();
            }
          })
          .catch(() => {
            setError('Une erreur est survenue, veuillez réessayer.');
            setLoading(false);
          });
      }
    });
  };

  const handleQuestionChange = useCallback(() => {
    const currentQuestion = questions[current];
    const previousQuestion = questions[current - 1];
    const motivation = getMotivation();

    // @see core/src/components/inputs/complex/heating-system-operating-mode.tsx
    if (currentQuestion.alias === questionResource.ALIAS_QUESTION_HEATING_SYSTEM_OPERATING_MODE) {
      const heatingSystemType = responses.heating_system_type;
      const heatingSystemEnergy = responses.heating_system_main_energy_vector;

      if (heatingSystemEnergy === 1 && ![6, 7, 8].includes(heatingSystemType)) {
        // Question is skipped
        return;
      }
    }

    handleSurveyPageLoaded(current === 0);

    if (currentQuestion?.group?.id !== previousQuestion?.group?.id) {
      handleSurveyQuestion(currentQuestion, responses, 'view_chapter', motivation);
    }

    handleSurveyQuestion(currentQuestion, responses, 'view_question', motivation);
  }, [current, questions, responses]);

  useEffect(() => {
    if (!loading) {
      handleQuestionChange();
    }
  }, [current, loading]);

  useEffect(() => {
    if (idParam === 'creer' && user?.survey) {
      navigate('/dashboard');
      return () => null;
    }

    initialize();

    return () => {
      if (observerTimeout !== null) {
        clearTimeout(observerTimeout);
      }

      setToken(null);
      token = null;
    };
  }, [location.state]);

  if (status === surveyResource.STATUS_PROCESSING) {
    return <LoaderEnd currentStage={currentStage || 0} processingTaskUrl={processingTaskUrl} id={id} query={buildQuery()} />;
  }

  if (loading) {
    return <LoaderSimple />;
  }

  if (rejected) {
    return <Rejected backToSurvey={() => setRejected(false)} />;
  }

  if (resolvingTaskError?.errorMessage) {
    return (
      <div className="container alert alert-danger mt-3 text-center">
        <h3 className="alert-heading">Une erreur est survenue</h3>
        <p className={isDebugCstbEnabled ? '' : 'mb-0'}>
          {/* Build error code like GR001, D002, D010, .. */}
          Code erreur <strong>GR{String(resolvingTaskError?.failingStage || 0).padStart(3, '0')}</strong>
          <br />
          Veuillez contacter le 04 28 70 82 73
        </p>
        {isDebugCstbEnabled && (
          <>
            <hr />
            <p className="mb-0">{resolvingTaskError.errorMessage}</p>
          </>
        )}
      </div>
    );
  }

  const userSurvey = user?.survey?.id;

  return (
    <>
      {(error || cstbError) && (
        <div className="alert alert-danger">
          {error || cstbError}
          {cstbError && (
            <button
              type="button"
              className="btn btn-default"
              onClick={(e) => {
                e.preventDefault();
                downloadPayload(String(id), buildQuery());
              }}
            >
              Télécharger le payload
            </button>
          )}
        </div>
      )}
      {userSurvey && userSurvey !== Number(id) && <ReplacingSurvey id={id} callback={handleContinueLater} />}
      <SurveyDisplayer handleComplete={handleComplete} handleContinueLater={handleContinueLater} handleReject={handleReject} handleNext={handleNext} isResolving={status === surveyResource.STATUS_RESOLVING} rawResponses={rawResponses} edition={updateContext} />
    </>
  );
}
