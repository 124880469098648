import { useEffect, useState, useCallback } from 'react';
import { QuestionGroup } from '@concerto-home/core';
import PrimeApi from 'features/primes/simulation/questionnaire/services/primeApi';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

// Hook to fetch initial question

interface useInitialQuestionProps {
  groups: QuestionGroup[];
  partenaire: string | null;
  surveyId: number;
}

const useInitialQuestion = ({ groups, partenaire, surveyId }: useInitialQuestionProps) => {
  const [questions, setQuestions] = useState<QuestionPrime[]>([]);
  const [isResolving, setIsResolving] = useState(true);

  const fetchInitialQuestion = useCallback(async () => {
    try {
      const questionResponse = await PrimeApi.getQuestion({
        partenaire,
        surveyId,
      });
      if (questionResponse) {
        setQuestions([questionResponse]);
        setIsResolving(false);
      } else {
        setIsResolving(true);
      }
    } catch (error) {
      console.error('Failed to fetch initial question:', error);
      setIsResolving(true);
    }
  }, [partenaire, surveyId]);

  useEffect(() => {
    if (groups.length > 0) {
      fetchInitialQuestion();
    }
  }, [groups.length, fetchInitialQuestion]);

  return { questions, setQuestions, isResolving };
};

export default useInitialQuestion;
